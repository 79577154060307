import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FavoritePath } from '@app/home/features/gas-block/models/favorite-path.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoritePathService {
  private baseUrl: string = environment.apiUrl + '/favoritepath';

  constructor(private http: HttpClient) {}

  public getFavoritePaths(): Observable<Array<FavoritePath>> {
    return this.http.get<Array<FavoritePath>>(this.baseUrl);
  }

  public removeFavoritePath(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  public upsertFavoritePath(favoritePath: FavoritePath): Observable<FavoritePath> {
    return this.http.post<FavoritePath>(this.baseUrl, { ...favoritePath, pointList: favoritePath.points.join(',') });
  }
}
