import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Piston } from '@core/models/piston.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PistonService {
  private baseUrl: string = environment.apiUrl + '/piston';

  constructor(private http: HttpClient) {}

  public getPistons(): Observable<Array<Piston>> {
    return this.http.get<Array<Piston>>(this.baseUrl);
  }

  public savePiston(piston: Piston): Observable<Piston> {
    return this.http.post<Piston>(this.baseUrl, piston);
  }

  public editPiston(data: { id: string; absoluteDistance: number; distance: number }): Observable<Piston> {
    return this.http.post<Piston>(`${this.baseUrl}/edit-piston`, data);
  }

  public removePistonById(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
